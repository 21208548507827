@tailwind base;
@tailwind components;
@tailwind utilities;

:root{ --swiper-theme-color : #DFDDE6 !important}

@layer components{
    .bgOpaque{
        background-color: rgba(0, 0, 0, 0.5);
    }
    .margins{
        @apply outline outline-c_Mischka outline-1 rounded-md
    }
    .flexAllCenter{
        @apply flex justify-center items-center;
    }
    /* Estilo de fuente */
    .txtPrincipal{
        font-family: "Hind Madurai", Helvetica, Arial, sans-serif; 
    }
    .txtSecondary{
        font-family: 'Ubuntu', cursive, sans-serif; 
    }
    /* Tamaños de fuente */
    .txtSm{
        font-size:  calc(0.5em + 0.5vw);
        line-height: 1.2em;
    }
    .txtMd{
        font-size:  calc(0.5em + 0.7vw);
        line-height: 1.2em;
    }
    .txtLg{
        font-size:  calc(0.8em + 0.9vw);
        line-height: 1.2em;
    }
    .txtXl{
        font-size:  calc(1.3em + 1.5vw);
        line-height: 1em;
    }
    .txtXl2{
        font-size:  calc(1.5em + 1.8vw);
        line-height: 1em;
    }


    /* Estilos PT1 */
.bg_Portada{
    @apply bg-cover bg-center;
    background-image: url(/public/backgrounds/elementsSm-550-500.png);    }
.bg_PortadaMd{
    @apply bg-cover bg-center;
    background-image: url(/public/backgrounds/elementsMd-550-800.png);    }
.bg_PortadaLg{
    @apply bg-cover bg-center;
    background-image: url(/public/backgrounds/elementsLg-600-1300.png);    }
.bg_Moon{
    @apply bg-cover bg-center;
    background-image: url(/public/backgrounds/bgMoonV5-redy-600-1300.png);    }

.bg_Stars{
    @apply bg-cover bg-center;
    background-image: url(/public/backgrounds/fondoSm-550-500.png);    }
.bg_StarsMd{
    @apply bg-cover bg-center;
    background-image: url(/public/backgrounds/fondoMd-550-800.png);    }
.bg_StarsLg{
    @apply bg-cover bg-center;
    background-image: url(/public/backgrounds/fondoLg-850-1700.png);   }

    /* Estilos para BurgueBar */
.burguerAct{
    @apply w-screen h-9/10 flexAllCenter flex-col overflow-hidden top-[calc(10%)] fixed right-0 transition-all bg-c_Ebony justify-around
    lg:static lg:h-full lg:w-1/2 lg:flex-row lg:justify-between;
}

.burguerDes{
    @apply w-0 h-0       flexAllCenter flex-col overflow-hidden fixed top-[calc(10%)] right-0 transition-all
    lg:static lg:h-full lg:w-1/2 lg:flex-row lg:justify-between;
}
    /* Clases de los links del navbar */
    .inactiveA{
        @apply p-2 rounded-lg transition-all hover:bg-c_Mischka hover:text-c_Ebony hover:border-c_Ebony;
    }
    .activeA{
        @apply border-y-2 border-c_Mischka p-2 rounded-lg transition-all hover:bg-c_Mischka hover:text-c_Ebony hover:border-c_Ebony;
    }

    /* Clases del aviso copy */
    .hcopy{
        @apply flexAllCenter fixed bg-c_Purple p-3 top-1/2 right-1/4 z-20 rounded-lg border-2 border-c_Ebony opacity-0 transition-all duration-500
    }
    .copy{
        @apply flexAllCenter fixed bg-c_Purple p-3 top-1/2 right-1/4 z-20 rounded-lg border-2 border-c_Ebony opacity-100 transition-all shadow-lg shadow-c_Mischka duration-500
    }
    
    .hcopy2{
        @apply flexAllCenter absolute bg-c_Mischka bottom-[calc(125%)] left-[calc(10%)] p-3 z-20 rounded-lg opacity-0 transition-all duration-500  text-c_Ebony txtXl
        md:txtLg lg:txtMd;
    }
    .copy2{
        @apply flexAllCenter absolute bg-c_Mischka bottom-[calc(125%)] left-[calc(10%)] p-3 z-20 rounded-lg opacity-100 transition-all shadow-lg shadow-c_Ebony duration-500  text-c_Ebony txtXl
        md:txtLg lg:txtMd;
    }

    /* Backgrounds Sections */
    .bgConoc1{
        @apply bg-cover bg-center;
        background-image: url(/public/Others/tailwind-nextjs.png);
    }
    .bgConoc2{
        @apply bg-cover bg-center;
        background-image: url(/public/Others/stcakMern.jpg);
    }
    .bgConoc3{
        @apply bg-cover bg-center;
        background-image: url(/public/Others/mvcCore.jpg);
    }
    .bgConoc4{
        @apply bg-cover bg-center;
        background-image: url(/public/Others/backend.png);
    }
    .bgConoc5{
        @apply bg-cover bg-center;
        background-image: url(/public/Others/DBConoc.png);
    }
    .bgConoc6{
        @apply bg-cover bg-center;
        background-image: url(/public/Others/POOConoc.jpg);
    }
    .bgConoc7{
        @apply bg-cover bg-center;
        background-image: url(/public/Others/Figma.png);
    }
    .bgServ1{
        @apply bg-cover bg-center;
        background-image: url(/public/Others/DesarrolloydisenoW.jpg);
    }
    .bgServ2{
        @apply bg-cover bg-center;
        background-image: url(/public/Others/mantenimientoW.jpg);
    }
    .bgServ3{
        @apply bg-cover bg-center;
        background-image: url(/public/Others/mantenimientoW.jpg);
    }
    .bgServ4{
        @apply bg-cover bg-center;
        background-image: url(/public/Others/asesoria.png);
    }
    .bgPort1{
        @apply bg-cover bg-center;
        background-image: url(/public/projectImages/Portfolio.jpeg);
    }
    .bgPort2{
        @apply bg-cover bg-center;
        background-image: url(/public/projectImages/SuperGames.jpeg);
    }
    /* Other */
    .App{

    }
    .spContainer{
        color: #DFDDE6;
        font-family: "Hind Madurai", Helvetica, Arial, sans-serif; 
        overflow-x: hidden;
        @apply w-screen h-screen;
        scroll-snap-type: y mandatory;
        scroll-behavior: smooth;
    }
    .scrollEfect{
        scroll-snap-align: end;
    }
    .scrollEfectII{
        scroll-snap-align: start;
    }
    .scrollEfectIII{
        scroll-snap-align: center;
    }
    .mt_screen{
        margin-top: 90vh;
    }
    .leftBarr{
        border-right: 1px solid #DFDDE6;
    }

    /* Clases para cajas con glassmorfismo */
    .glassBox{
        /* @apply rounded-lg p-6; */
        @apply bg-opacity-40 bg-c_Purple backdrop-filter backdrop-blur-lg border border-c_Purple border-opacity-50 rounded-lg shadow-lg shadow-c_Ebony
    }
    .glassDark{
        @apply bg-opacity-60 bg-c_Ebony backdrop-filter backdrop-blur-lg border border-c_Ebony border-opacity-50 rounded-lg shadow-lg shadow-c_Ebony
    }
    /* Drop shadow */
    .Dshadow{
        filter: drop-shadow(0px 0px 10px #04040E);
    }
    .Lshadow{
        filter: drop-shadow(0px 0px 5px #DFDDE6);
    }
    /* Clases de formulario */
    .errorCamp{
        @apply shadow-red-600 border-red-600 !important;
    }
}